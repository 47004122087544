body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}

.navbar {
  margin-bottom: 30px;
}

.progress {
  margin: 50px 0px 50px 30px;
}

.bg-light {
  opacity: 1;
}

.opaque {
  opacity: 0.8;
}
.nonopaque {
  opacity: 1;
}

#cvTitle:hover {
  text-shadow: 0px 0px 20px black;

  -webkit-transition-property: text-shadow;
  transition-property: text-shadow;

  -webkit-transition: 2s;
  transition: 2s;
}

/* 
.developer {
  background-image: url(./images/background.jpg);
  background-size: 100%;
}
*/

.navBarBckg {
  background-image: url(./images/shutterstock_1317028097.jpg);
  background-position: center;
  background-size: 120%;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}
